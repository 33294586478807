/** @jsx jsx */
import { jsx } from "theme-ui"
import Layout from "../components/layout"
import Banner from "../components/contact/Banner"
import ContactForm from "../components/contact/ContactFrom"
import data from "../content/contact/banner.json"
import Header from "../components/common/Header"
import MetaTags from "react-meta-tags"
import SEO from "../components/seo"

const Contact = () => {
  return (
    <Layout className=" Cresol | contact-page " sx={contactStyles.contactPage}>
      <SEO
        title="Contact"
        description="Our experienced team of IT professionals is ready to assist you with a wide range of services, including: Custom Software Development,Web Development and Design,Mobile App Development,Cloud Solutions,ITConsulting,Data Analytics and Business Intelligence,Cybersecurity Services,IT Infrastructure Management,
        Whether you have questions about our services, need a tailored solution for your business, or want to discuss a potential project, we are here to help. Our dedicated team will promptly respond to your inquiries and provide the information you need."
      />

      <div>
        <Header
          title="Contact"
          description="Let’s talk about what you want to accomplish and how we can make it happen."
        />
        {/* <Banner image={data.image} title={data.heading} /> */}
        <ContactForm />
      </div>
    </Layout>
  )
}

export default Contact

const contactStyles = {
  contactPage: {
    input: {
      border: "6px solid",
      borderColor: "inputBorder",
      bg: "inputBackground",
      outline: "none",
    },
    textarea: {
      border: "6px solid",
      borderColor: "inputBorder",
      bg: "inputBackground",
      outline: "none",
    },
  },
}
